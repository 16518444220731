import './components/table/editors';
import './static/style/app.scss';

import Vue from 'vue';

import { getExtensionPoint, registerExtensionPoint } from '../../core/extensionPoints';
import { components } from './components';
import { dashboardWidgets } from './components/dashboard';
import { PosterTableRenderers } from './components/table/renderers';
import { getViewer, registerViewer } from './components/viewers';
import PosterRoutes from './routes';
import { addPosterService } from './services';
import { PosterAttributesService } from './services/attributesService';
import { CartStateService } from './services/cartState';
import { ImportModesService } from './services/importModesService';
import { PdfConverter } from './services/pdfConverter';
import { PDFDownloaderService } from './services/pdfDownloaderService';
import { PosterDashboardsService } from './services/posterDashboardsService';
import { PosterResourcesService } from './services/posterResourcesService';
import { SendPosterService } from './services/sendPosterService';
import { SignagesService } from './services/signagesService';
import { SignagesSynchronization } from './services/signagesSynchronization';
import { TemplatesService } from './services/templatesService';
import { ValuesService } from './services/valuesService';
import PosterModule, { NAMESPACE as POSTER_NAMESPACE } from './store/modules/poster';
import {
  NAMESPACE as SEND_POSTERS_NAMESPACE,
  SendPostersModule,
} from './store/modules/sendPosters';
import { formatters } from './utils/formatters';

const moduleName = 'poster';

/**
 * Function to install poster app module
 * */
function install(platformExtension) {
  registerExtensionPoint(moduleName, {
    tableRenderers: PosterTableRenderers,
    viewers: {
      registerViewer,
      getViewer,
    },
  });

  const formattingManager = getExtensionPoint('poster.engine').formattingManager;
  for (const formatter of formatters) {
    formattingManager.registerFormatter(formatter.name, formatter.func);
  }

  for (const component of components) {
    Vue.component(component.name, component);
  }

  platformExtension.router.addRoutes(PosterRoutes);

  platformExtension.store.registerModule(POSTER_NAMESPACE, PosterModule);
  platformExtension.store.registerModule(SEND_POSTERS_NAMESPACE, SendPostersModule);

  const templatesService = new TemplatesService();
  addPosterService('signagesSync', new SignagesSynchronization());
  addPosterService('importModes', new ImportModesService());
  addPosterService('PDFDownloader', new PDFDownloaderService());
  addPosterService('signages', new SignagesService());
  addPosterService('templates', templatesService);
  addPosterService('values', new ValuesService());
  addPosterService('pdfConverter', PdfConverter);
  addPosterService('posterAttributes', new PosterAttributesService());
  addPosterService('sendPosters', new SendPosterService());
  addPosterService('cartState', new CartStateService());
  addPosterService('dashboards', new PosterDashboardsService());
  addPosterService('posterResourcesService', new PosterResourcesService());

  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .componentsManager.$on(
      'error',
      templatesService.onTemplateComponentError.bind(templatesService)
    );
  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .fontsManager.$on('error', templatesService.onTemplateComponentError.bind(templatesService));

  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .lesserEval.$on('error', (errorCode, data) => {
      console.error(`poster engine lesserEval error: ${errorCode}`, data);
    });

  platformExtension.extensionPoints
    .getExtensionPoint('poster.engine')
    .lesserEval.$on('warn', (data) => {
      console.warn('poster engine lesserEval warning: ', data);
    });

  dashboardWidgets.forEach((def) => {
    platformExtension.dashboardWidgetsManager.registerWidget(def.name, def.widget);
  });
}

getExtensionPoint('platform').services.getService('moduleManager').registerModule({
  name: moduleName,
  install,
});
