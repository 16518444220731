<template>
  <pui-dialog
    ref="dialog"
    :title="$t('poster.creation.send_selection_confirmation.title')"
    :showCloseButton="false"
    class="send-selection-confirmation-dialog send-selection-confirmation-dialog__root"
    transition="slide-up"
    width="40%"
  >
    <div slot="dialog-action" class="dialog-action">
      <pui-button outline variant="secondary" @click="onCancel(close)">
        {{ $t('poster.creation.send_selection_confirmation.cancel') }}
      </pui-button>
      <pui-button
        :disabled="nbSelectedSignageItemsInvalid === nbSelectedSignageItems"
        variant="primary"
        @click="onConfirm(close)"
      >
        {{ $t('poster.creation.send_selection_confirmation.confirm') }}
      </pui-button>
    </div>

    <pui-error
      :picto="
        nbSelectedSignageItemsInvalid === nbSelectedSignageItems
          ? 'mdi-close-circle'
          : nbSelectedSignageItemsInvalid
          ? 'mdi-alert'
          : 'mdi-check-circle'
      "
      class="send-selection-confirmation-dialog__message"
    >
      <template v-if="nbSelectedSignageItemsInvalid === nbSelectedSignageItems">
        <i18n path="poster.creation.send_selection_confirmation.invalid.primary">
          <template #countMsg>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
              <template #count>{{ nbSelectedSignageItemsInvalid }}</template>
            </i18n>
          </template>
        </i18n>
        <i18n path="poster.creation.send_selection_confirmation.invalid.secondary">
          <template #cancel>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.cancel"></i18n>
          </template>
        </i18n>
      </template>
      <template v-else-if="nbSelectedSignageItemsInvalid">
        <i18n path="poster.creation.send_selection_confirmation.partially_valid.primary">
          <template #countInvalidMsg>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
              <template #count>{{ nbSelectedSignageItemsInvalid }}</template>
            </i18n>
          </template>
          <template #countSelectedMsg>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
              <template #count>{{ nbSelectedSignageItems }}</template>
            </i18n>
          </template>
        </i18n>
        <i18n path="poster.creation.send_selection_confirmation.partially_valid.secondary">
          <template #confirm>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.confirm">
            </i18n>
          </template>
          <template #countValid>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
              <template #count>{{
                nbSelectedSignageItems - nbSelectedSignageItemsInvalid
              }}</template>
            </i18n>
          </template>
          <template #countSelected>{{ nbSelectedSignageItems }}</template>
        </i18n>
        <i18n path="poster.creation.send_selection_confirmation.partially_valid.tertiary"> </i18n>
      </template>
      <template v-else>
        <i18n path="poster.creation.send_selection_confirmation.valid.primary">
          <template #countMsg>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.count">
              <template #count>{{ nbSelectedSignageItems }}</template>
            </i18n>
          </template>
        </i18n>
        <i18n path="poster.creation.send_selection_confirmation.valid.secondary">
          <template #confirm>
            <i18n class="bold-txt" path="poster.creation.send_selection_confirmation.confirm">
            </i18n>
          </template>
          <template #countMsg>{{ nbSelectedSignageItems }}</template>
        </i18n>
      </template>
    </pui-error>
  </pui-dialog>
</template>

<script>
export default {
  name: 'PosterSendSelectionConfirmationDialog',
  props: {
    /**
     * The number of selected posters
     */
    nbSelectedSignageItems: {
      type: Number,
      required: true,
    },
    /**
     * The number of invalid posters that are selected
     */
    nbSelectedSignageItemsInvalid: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      onCancel: () => {},
      onConfirm: () => {},
    };
  },
  methods: {
    /**
     * Close dialog
     */
    close() {
      if (this.$refs.dialog) {
        this.$refs.dialog.close();
      }
      this.onCancel = () => {};
      this.onConfirm = () => {};
    },
    /**
     * Opens the dialog
     *
     * @param {object} callbacks - object of user callbacks
     * @param {Function} callbacks.onCancel - called when the user wants to continue editing the poster
     * @param {Function} callbacks.onConfirm - called when the user wants
     * to continue sending the posters
     */
    open({ onCancel, onConfirm } = {}) {
      this.onCancel = onCancel;
      this.onConfirm = onConfirm;

      if (this.$refs.dialog) {
        this.$refs.dialog.open();
      }
    },
  },
};
</script>
