import store from '../../../core/store';
import { NAMESPACE as POSTER_NAMESPACE, SIGNAGES_ARE_SYNCHRONIZED } from '../store/modules/poster';
import { ISignagesSynchronization } from './types';

export class SignagesSynchronization implements ISignagesSynchronization {
  timeoutSync: number | null;

  constructor() {
    this.timeoutSync = null;
  }

  /**
   * Function to start synchronization delay
   * After delay we commit the new status in store
   */
  startSynchronizationDelay(): void {
    store.commit(`${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}`, false);
    this.resetSynchronizationDelay();
    this.timeoutSync = window.setTimeout(() => {
      store.commit(`${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}`, true);
    }, 2000);
  }

  /**
   * Function to reset synchronization delay
   */
  resetSynchronizationDelay(): void {
    if (this.timeoutSync) {
      clearTimeout(this.timeoutSync);
    }
  }

  /**
   * Function to execute action during synchronization
   * @param cb - executed function during synchronization
   * @param opts - listener options
   * @param opts.once - if the callback should only be invoked once
   * @returns the function to unsubscribe from 'after sync' events. No need to invoke
   * it if `opts.once: true`
   */
  actionDuringSynchronization(cb: () => void, { once } = { once: false }): () => void {
    // If synchronization is already in process, execute cb
    if (
      store &&
      store.state &&
      (store.state as any).templates &&
      !(store.state as any).templates.isSynchronized
    ) {
      cb();
      if (once) {
        return () => {
          // Do nothing
        };
      }
    }
    const unsubscribe = store.subscribe((mutation) => {
      if (
        mutation.type === `${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}` &&
        mutation.payload === false
      ) {
        cb();
        if (once) {
          unsubscribe();
        }
      }
    });

    return unsubscribe;
  }

  /**
   * Function to execute cb when synchronization are finished
   * @param cb - executed function after synchronization
   * @param opts - listener options
   * @param opts.once - if the callback should only be invoked once
   * @param opts.disableImmediate - disables immediate checking the sync state. This means
   * the callback will only be called if the sync state changes in the future
   * @returns the function to unsubscribe from 'after sync' events. No need to invoke
   * it if `opts.once: true`
   */
  actionAfterSynchronization(
    cb: () => void,
    opts?: { once?: boolean; disableImmediate?: boolean }
  ): () => void {
    const mergedOpts = {
      once: false,
      disableImmediate: false,
      ...opts,
    };
    // If synchronization is already done execute cb
    if (
      !mergedOpts.disableImmediate &&
      store &&
      store.state &&
      (store.state as any).poster &&
      (store.state as any).poster.isSynchronized
    ) {
      cb();
      if (mergedOpts.once) {
        return () => {
          // Do nothing
        };
      }
    }

    const unsubscribe = store.subscribe((mutation) => {
      if (
        mutation.type === `${POSTER_NAMESPACE}/${SIGNAGES_ARE_SYNCHRONIZED}` &&
        mutation.payload === true
      ) {
        cb();
        if (mergedOpts.once) {
          unsubscribe();
        }
      }
    });

    return unsubscribe;
  }
}
