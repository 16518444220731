<template>
  <pui-flex class="poster-default-viewer" flex="1" justifyContent="center">
    <poster-gallery-thumbnail :posterId="posterRequest.itemId"></poster-gallery-thumbnail>
  </pui-flex>
</template>

<script>
import { posterViewerMixin } from './posterViewerMixin';

export default {
  name: 'PuiPosterDefaultViewer',
  mixins: [posterViewerMixin],
};
</script>
