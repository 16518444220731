import Vue from 'vue';

import { USER_LOGOUT } from '../../../../core/store/modules/coreModule';

export const NAMESPACE = 'sendPosters';

export const SET_SEND_ACTION_CONF = 'SET_SEND_ACTION_CONF';
export const CLEAR_SEND_ACTION_CONF = 'CLEAR_SEND_ACTION_CONF';

export const SET_SEND_ACTION_FORM = 'SET_SEND_ACTION_FORM';
export const CLEAR_SEND_ACTION_FORM = 'CLEAR_SEND_ACTION_FORM';

export const SendPostersModule = {
  namespaced: true,
  state: {
    creationActionConfiguration: {},
    creationActionForm: {},
  },
  getters: {
    getActionConf: (state) => (key) => state.creationActionConfiguration[key],
    getActionForm: (state) => (key) => state.creationActionForm[key],
  },
  mutations: {
    [SET_SEND_ACTION_CONF](state, { key, conf }) {
      Vue.set(state.creationActionConfiguration, key, conf);
    },
    [CLEAR_SEND_ACTION_CONF](state, { key }) {
      Vue.set(state.creationActionConfiguration, key, null);
    },
    [SET_SEND_ACTION_FORM](state, { key, form }) {
      Vue.set(state.creationActionForm, key, form);
    },
    [CLEAR_SEND_ACTION_FORM](state, { key }) {
      Vue.set(state.creationActionForm, key, null);
    },
  },
  actions: {
    [USER_LOGOUT]: {
      root: true,
      handler(context) {
        context.state.creationActionConfiguration = {};
        context.state.creationActionForm = {};
      },
    },
  },
};
