import Vue from 'vue';

import store from '../../../core/store';
import { Response } from '../../../core/types/http';
import actionsApi, { Actions } from '../api/actions';
import { SignageForm } from '../api/forms';
import { CreationActions } from '../constants/index';
import {
  CLEAR_SEND_ACTION_CONF,
  CLEAR_SEND_ACTION_FORM,
  NAMESPACE as CREATION_NAMESPACE,
  SET_SEND_ACTION_CONF,
  SET_SEND_ACTION_FORM,
} from '../store/modules/sendPosters';
import { ISendPosterService } from './types';

/**
 * Prefix for the send action aliases
 */
const ACTION_ALIAS_PREFIX = 'poster_action_';

/**
 *
 * @param action - the internal action
 * @returns the send action's alias
 */
const internalActionToAlias = (action: CreationActions): string =>
  `${ACTION_ALIAS_PREFIX}${action}`;

export class SendPosterService implements ISendPosterService {
  /**
   * @param action - internal send action
   * @returns the stored action configuration
   */
  private _getStoredActionConfiguration(action: CreationActions): Actions.Action {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    return store.getters[`${CREATION_NAMESPACE}/getActionConf`](action);
  }

  /**
   * Retrieves the existing configuration or fetches it from the api
   *
   * @param action - the action whose configuration to retrieve
   */
  async retrieveActionConfiguration(action: CreationActions): Promise<Actions.Action> {
    const existingConf = this._getStoredActionConfiguration(action);
    if (existingConf) {
      return existingConf;
    }

    const actionAlias = internalActionToAlias(action);
    const { body: conf } = await actionsApi.getActionByAlias(actionAlias);

    store.commit(`${CREATION_NAMESPACE}/${SET_SEND_ACTION_CONF}`, { key: action, conf });

    return conf;
  }

  /**
   * Clears the action's configuration
   *
   * @param action - the action whose configuration to clear
   */
  clearActionConfiguration(action: CreationActions): void {
    store.commit(`${CREATION_NAMESPACE}/${CLEAR_SEND_ACTION_CONF}`, { key: action });
  }

  /**
   * Retrieves the existing form or fetches it from the api
   * WARNING: you must have previously loaded the action configuration
   *
   * @param action - the action whose form to retrieve
   */
  async retrieveActionForm(action: CreationActions): Promise<SignageForm> {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const existingForm = store.getters[`${CREATION_NAMESPACE}/getActionForm`](
      action
    ) as SignageForm;
    if (existingForm) {
      return existingForm;
    }

    const actionConf = this._getStoredActionConfiguration(action);
    if (!actionConf) {
      throw new Error(
        `Could not retrieve action form for ${action}: did not retrieve action configuration beforehand`
      );
    }
    if (!actionConf.form?.href) {
      throw new Error(`Could not retrieve action form for ${action}: there is no form`);
    }

    const { body: form } = await (Vue.http.get(actionConf.form.href) as Response<SignageForm>);

    store.commit(`${CREATION_NAMESPACE}/${SET_SEND_ACTION_FORM}`, { key: action, form });

    return form;
  }

  /**
   * Clears the action's form
   *
   * @param action - the action whose form to clear
   */
  clearActionForm(action: CreationActions): void {
    store.commit(`${CREATION_NAMESPACE}/${CLEAR_SEND_ACTION_FORM}`, { key: action });
  }
}
