import services from '../../../core/services';
import { IApplicationsService } from '../../../platform/services/types';
import { AttributeValues } from '../../common/api/attributes';
import { POSTER_APP_NAME } from '../constants';

/**
 * Default cart limit if unspecified in parameters
 */
const DEFAULT_CART_LIMIT = 1_000;

/**
 *
 * @param status - the poster status object
 * @returns the color of the status or the default grey
 */
export const getPosterStatusColor = (status?: {
  values?: AttributeValues.ValueObject[];
}): string => {
  let color = '';
  const val = status?.values?.find((val) => val.attributeAlias === 'signages_status_color');
  if (val && typeof val.value === 'string') {
    color = val.value;
  }

  // Return the color or grey default
  return color || '#cccccc';
};

/**
 *
 * @returns the limit of posters that can be sent or 0 if it cannot be found
 */
export const getPosterLimit = (): number => {
  const limit = services
    .getService<IApplicationsService>('applications')
    ?.getAppDeepParameter(POSTER_APP_NAME, 'actionsSettings', 'posterLimit', true);

  return typeof limit === 'number' ? limit : 0;
};

/**
 *
 * @returns the limit of posters that can exist in the cart
 */
export const getCartLimit = (): number => {
  const limit = services
    .getService<IApplicationsService>('applications')
    ?.getAppDeepParameter(POSTER_APP_NAME, 'screensSettings', 'creation.cart.limit', true);

  return typeof limit === 'number' ? limit : DEFAULT_CART_LIMIT;
};
