import i18n from '../../../core/i18n';
import services from '../../../core/services';
import {
  IAlertsService,
  IAnalyticsService,
  INotificationsService,
} from '../../../platform/services/types';
import { AttributeValues } from '../../common/api/attributes';
import { BulkActionService } from '../../common/services/bulkActionService';
import convert, { Convert } from '../api/convert';
import puiPosterCreationNotification from '../components/creation/PosterCreationNotification.vue';
import { CreationActions, PosterCreationStates } from '../constants';
import { getPosterService } from '.';
import { BulkActionDetails } from './../../common/api/bulk';
import { IPDFDownloaderService } from './types';

/**
 * Type of the bulk action conversion details
 */
type ConvertDetails = BulkActionDetails<Convert.PdfResult>;

export interface ConversionPromiseFns {
  resolve: (value: string[] | PromiseLike<string[]>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason: any) => void;
}

/**
 * Service to convert posters to pdf
 */
export class PdfConverter {
  notificationId = -1;
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  resolve: (value: string[] | PromiseLike<string[]>) => void = () => {};
  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-empty-function
  reject: (reason: any) => void = () => {};

  // eslint-disable-next-line no-useless-constructor
  constructor(
    private readonly idsToConvert: string[],
    private readonly trackEvent: string,
    promiseFns?: ConversionPromiseFns
  ) {
    if (promiseFns) {
      this.resolve = promiseFns.resolve;
      this.reject = promiseFns.reject;
    }
  }

  /**
   * Converts a list of posters with their ids.
   * This receives promise callbacks so that the calling function can properly catch errors.
   *
   * @param idsToConvert - Array of posters ids to convert
   * @param parameters - Conversion parameters
   * @param trackEvent - the event to track the download with
   * @param promiseFns - object of promise callbacks to receive the final conversion+download result
   * @returns the list of pdf urls, or rejects with a reason
   */
  static async convertToPdf(
    idsToConvert: string[],
    parameters: { values: AttributeValues.ValueObject[] | null; [x: string]: any },
    trackEvent: string,
    promiseFns?: ConversionPromiseFns
  ): Promise<void> {
    let convertor;

    try {
      convertor = new PdfConverter(idsToConvert, trackEvent, promiseFns);
      await convertor.init();

      const createConversionRes = await convert.convertToPdfAsync(idsToConvert, parameters);

      void BulkActionService.pollBulkAction<ConvertDetails>(createConversionRes.body, {
        onStatusFinished: convertor.onConversionFinished.bind(convertor),
        onStatusInError: convertor.onConversionError.bind(convertor),
      });
    } catch (errorResponse) {
      convertor?.onStartError(errorResponse);
    }
  }

  /**
   * Creates the download notification
   */
  public async init() {
    this.notificationId =
      (await services
        .getService<INotificationsService>('notifications')
        ?.createNotification(puiPosterCreationNotification, {
          state: PosterCreationStates.IN_PROGRESS,
          action: CreationActions.DOWNLOAD,
          count: this.idsToConvert.length,
          pdfUrls: [],
        })) || -1;

    services.getService<INotificationsService>('notifications')?.openNotifications();
  }

  /**
   * Updates the download notification
   *
   * @param data - notification data
   */
  private updateNotification(data: object): void {
    if (this.notificationId !== -1) {
      services
        .getService<INotificationsService>('notifications')
        ?.updateNotification(this.notificationId, data);
    }
  }

  /**
   * Callback for the finished conversion's details
   *
   * @param details - conversion details
   */
  private async onConversionFinished(details: ConvertDetails): Promise<void> {
    if (
      details.result.status === Convert.ResultStatus.OK ||
      details.result.status === Convert.ResultStatus.WARNING
    ) {
      try {
        if (details.result.status === Convert.ResultStatus.WARNING) {
          services
            .getService<IAlertsService>('alerts')
            ?.alertWarning(i18n.t('poster.convert.pdf.warning') as string);
        }

        const pdfUrls = details.result.urls;

        this.updateNotification({
          state: PosterCreationStates.DONE_SUCCESS,
          pdfUrls,
        });

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        services
          .getService<IAnalyticsService>('analytics')
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          ?.trackEvent(this.trackEvent, null as any, {
            signagesNumber: this.idsToConvert.length,
            PDFsNumber: pdfUrls != null ? pdfUrls.length : 0,
          });

        await getPosterService<IPDFDownloaderService>('PDFDownloader')?.downloadAll(pdfUrls);

        this.resolve(pdfUrls);
      } catch (err) {
        services
          .getService<IAlertsService>('alerts')
          ?.alertError(i18n.t('poster.creation.creation_tasks.alerts.failed.download') as string);

        this.reject(err);
      }
    } else {
      this.updateNotification({
        state: PosterCreationStates.DONE_ERROR,
      });

      this.reject(details);
    }
  }

  /**
   * Callback for the errored conversion's details
   *
   * @param details - conversion details
   */
  private onConversionError(details: ConvertDetails): void {
    services
      .getService<IAlertsService>('alerts')
      ?.alertError(i18n.t('poster.convert.pdf.error') as string);

    this.updateNotification({
      state: PosterCreationStates.DONE_ERROR,
    });

    this.reject(details);
  }

  /**
   * Callback for a conversion start error
   *
   * @param errorResponse - error from creating the conversion, or other error
   */
  private onStartError(errorResponse: any): void {
    // Alert error
    if (errorResponse.status === 408) {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.convert.pdf.server_unresponsive') as string);
    } else {
      services
        .getService<IAlertsService>('alerts')
        ?.alertError(i18n.t('poster.convert.pdf.error') as string);
    }

    this.updateNotification({
      state: PosterCreationStates.DONE_ERROR,
    });

    this.reject(errorResponse);
  }
}
