export default {
  // Menu
  MENU_GO_TO_DASHBOARD: 'signages.menu.goToDashboard',
  MENU_GO_TO_BATCH_CREATION: 'signages.menu.goToSignageBatchCreation',
  MENU_GO_TO_CREATION: 'signages.menu.goToCreation',
  MENU_GO_TO_DEMANDS: 'signages.menu.goToDemands',
  MENU_GO_TO_HELP: 'signages.menu.help',

  // Dashboard
  DASHBOARD_GO_TO_BATCH_CREATION: 'signages.dashboard.goToBatchCreation',
  DASHBOARD_GO_TO_CREATION: 'signages.dashboard.goToCreation',
  DASHBOARD_MY_DEMANDS: 'signages.dashboard.goToMyDemands',
  DASHBOARD_FILTER_LOGS: 'signages.dashboard.filterLogs',
  DASHBOARD_SIGNAGES_TO_PROCESS: 'signages.dashboard.signagesToProcess',

  // Quick signage creation
  QUICK_NEW: 'signages.quick.chooseNew',
  QUICK_CHOOSE_NEW: 'signages.quick.new',
  QUICK_GO_TO_CREATION: 'signages.quick.goToCreation',
  QUICK_GO_TO_BATCH_CREATION: 'signages.quick.goToBatchCreation',
  QUICK_CANCEL_IMPORT: 'signages.quick.cancelImport',
  QUICK_TOGGLE_CART: 'signages.quick.toggleBasket',
  QUICK_DELETE_SIGNAGE: 'signages.quick.deleteSignage',
  QUICK_IMPORT_SIGNAGES: 'signages.quick.importSignages',
  QUICK_OPEN_SEND_CONFIRMATION: 'signages.quick.openSendConfirmation',
  QUICK_SEND: 'signages.quick.send',
  QUICK_OPEN_DOWNLOAD_CONFIRMATION: 'signages.quick.openDownloadConfirmation',
  QUICK_DOWNLOAD: 'signages.quick.download',
  QUICK_OPEN_PRINT_CONFIRMATION: 'signages.quick.openPrintConfirmation',
  QUICK_PRINT: 'signages.quick.print',
  QUICK_THUMBNAIL_ERROR: 'signages.quick.thumbnail.error',

  // Signages listing
  LOGS_MODE_LIST: 'signages.logs.listMode',
  LOGS_MODE_GALLERY: 'signages.logs.galleryMode',
  LOGS_TOGGLE_FILTERS_PANEL: 'signages.logs.toggleFiltersPanel',
  LOGS_RESET_FILTERS: 'signages.logs.resetFilters',
  LOGS_APPLY_FILTERS: 'signages.logs.applyFilters',
  LOGS_EDIT_SIGNAGE: 'signages.logs.editSignage',
  LOGS_CANCEL_EDITION: 'signages.logs.cancelEdition',
  LOGS_SAVE_EDITION: 'signages.logs.saveEdition',
  LOGS_DOWNLOAD_SIGNAGE: 'signages.logs.downloadSignage',
  LOGS_PRINT_SIGNAGE: 'signages.logs.printSignage',
  LOGS_ARCHIVE_SIGNAGE: 'signages.logs.archiveSignage',
  LOGS_DELETE_SIGNAGE: 'signages.logs.deleteSignage',
};
