<template>
  <pui-flex class="poster-edit-svg-viewer" flex="1">
    <!-- Prevent rendering poster while fonts are loading otherwise -->
    <!-- could trigger text calculations before fonts are available -->
    <pui-flex
      v-if="fontsLoading"
      class="preview-fonts-loading"
      flex="1"
      direction="column"
      justifyContent="center"
      alignItems="center"
    >
      <pui-common-spinner :hideMessage="true"></pui-common-spinner>
    </pui-flex>

    <pui-flex
      v-else-if="fontsError"
      class="preview-fonts-error"
      flex="1"
      direction="column"
      alignItems="center"
    >
      <i class="mdi mdi-alert error-icon" />
      <i18n path="poster.demands.editing.error.fonts.primary"></i18n>
      <i18n path="poster.demands.editing.error.fonts.secondary">
        <template #clickToReload>
          <span class="reload-button" @click="emit('reloadFromFontsError')">{{
            $t('poster.demands.editing.error.fonts.click_to_reload')
          }}</span>
        </template>
      </i18n>
    </pui-flex>

    <pui-document-viewer
      v-else-if="!fontsLoading"
      ref="viewer"
      :template="currentTemplate"
      :bindings="values"
      :types="valuesTypes"
      :templateScope="ComponentScopes.Preview"
    />
  </pui-flex>
</template>

<script>
import { ComponentScopes } from 'piivo-poster-engine/src/lib/constants/scopes';

import { posterViewerMixin } from './posterViewerMixin';

export default {
  name: 'PuiPosterDefaultViewer',
  mixins: [posterViewerMixin],
  data() {
    return {
      // Constants
      ComponentScopes,
    };
  },
  methods: {
    /**
     * @returns {Element} the main svg element of the poster
     */
    getSvgEl() {
      return this.$refs.viewer ? this.$refs.viewer.getSvgEl() : null;
    },
  },
};
</script>
