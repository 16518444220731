<template>
  <pui-flex flex="1" class="poster-family-carousel poster-family-carousel__root">
    <pui-chip
      v-for="item in items"
      :key="getItemId(item)"
      class="poster-family-carousel__chip"
      outlined
      rounded
      borderColor="var(--color-primary)"
      hoverColor="#fff"
      hoverBackgroundColor="var(--color-primary-light)"
      :active="selectedIds.includes(getItemId(item))"
      @clickContent="$emit('change', [item])"
    >
      {{ piivoTranslate(item) }}
    </pui-chip>
  </pui-flex>
</template>

<script>
import dragScrollMixin from 'piivo-ui/src/mixins/dragScroll';
import Vue from 'vue';

export default {
  name: 'PosterFamilyCarousel',
  mixins: [dragScrollMixin],
  props: {
    /**
     * Array of families
     */
    items: {
      type: Array,
      default: () => [],
    },
    /**
     * The selected families
     */
    value: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    /**
     * @returns {string[]} array of selected family ids
     */
    selectedIds() {
      return this.value.map((f) => this.getItemId(f));
    },
  },
  methods: {
    // FILTERS
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    /**
     * @param {object} family - poster family object
     * @returns {string} the id of the family
     */
    getItemId(family) {
      return family.itemId;
    },
  },
};
</script>
