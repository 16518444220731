import Vue from 'vue';

import { Response } from '../../../core/types/http';
import { AttributeValues } from '../../common/api/attributes';

/**
 * Conversion API.
 */
const API_URL_BASE = '/api/signages/logs/convert';

/**
 * Async conversion API.
 */
const ASYNC_API_URL_BASE = '/api/signages/logs/convertasync';

export namespace Convert {
  /**
   * Conversion result status
   */
  export enum ResultStatus {
    OK = 'OK',
    WARNING = 'WARNING',
  }

  /**
   * Pdf conversion result
   */
  export interface PdfResult {
    status: ResultStatus;
    urls: string[];
  }
}

export default {
  /**
   * Converts a list of signages with their ids.
   *
   * @param idsToConvert  Array of signages id
   * @param parameters - Conversion parameters
   */
  async convertToPdf(
    idsToConvert: string[],
    parameters: { values: AttributeValues.ValueObject[] | null; [x: string]: any }
  ): Response<Convert.PdfResult> {
    return Vue.http.post(`${API_URL_BASE}/pdf`, {
      signageIds: idsToConvert,
      parameters,
    }) as Response<Convert.PdfResult>;
  },

  /**
   * Starts an async conversion of posters.
   *
   * @param idsToConvert  Array of signages id
   * @param parameters - Conversion parameters
   * @returns the bulk action id of the conversion
   */
  async convertToPdfAsync(
    idsToConvert: string[],
    parameters: { values: AttributeValues.ValueObject[] | null; [x: string]: any }
  ): Response<string> {
    return Vue.http.post(`${ASYNC_API_URL_BASE}/pdf`, {
      signageIds: idsToConvert,
      parameters,
    }) as Response<string>;
  },
};
