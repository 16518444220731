<template>
  <pui-flex class="pui-file-notification" direction="column" flex="1">
    <!-- CREATION IN PROGRESS -->
    <template v-if="data.state === PosterCreationStates.IN_PROGRESS">
      <pui-flex class="file-notification-title">
        <div class="notif-title-main">
          <span class="notif-title-main__picto mdi mdi-clock-outline"></span>
          {{ $t(`poster.creation.creation_tasks.notifications.in_progress.${data.action}.title`) }}
        </div>
      </pui-flex>
      <div class="file-notification-content">
        <template v-if="data.action !== CreationActions.DOWNLOAD">
          {{
            $t(`poster.creation.creation_tasks.notifications.in_progress.${data.action}.message`)
          }}
        </template>
        <pui-common-progress-linear hideMessage />
      </div>
    </template>

    <!-- CREATION SUCCESS -->
    <template v-else-if="data.state === PosterCreationStates.DONE_SUCCESS">
      <pui-flex class="file-notification-title notification-success">
        <div class="notif-title-main">
          <span class="notif-title-main__picto mdi mdi-check-circle-outline"></span>
          {{ $t(`poster.creation.creation_tasks.notifications.success.${data.action}.title`) }}
        </div>
        <div class="notif-title-secondary">
          <pui-button
            picto="mdi-close"
            flat
            variant="secondary"
            @click.stop="dismissNotification"
          />
        </div>
      </pui-flex>
      <div class="file-notification-content">
        <div>
          {{
            $t(`poster.creation.creation_tasks.notifications.success.${data.action}.message`, {
              count: data.count,
            })
          }}
          <template v-if="data.action === CreationActions.DOWNLOAD">
            <pui-flex direction="column">
              <a
                v-for="url in downloadUrls"
                :key="url.id"
                :href="url.url"
                :download="url.filename"
                target="_blank"
                >{{ url.filename || url.url }}
              </a>
            </pui-flex>
          </template>
        </div>
      </div>
    </template>

    <!-- CREATION ERROR -->
    <template v-else-if="data.state === PosterCreationStates.DONE_ERROR">
      <pui-flex class="file-notification-title">
        <div class="notif-title-main">
          <span class="notif-title-main__picto mdi mdi-alert-circle-outline"></span>
          {{ $t(`poster.creation.creation_tasks.notifications.error.${data.action}.title`) }}
        </div>
        <div class="notif-title-secondary">
          <pui-button
            picto="mdi-close"
            flat
            variant="secondary"
            @click.stop="dismissNotification"
          />
        </div>
      </pui-flex>
      <div class="file-notification-content">
        <div>
          {{ $t(`poster.creation.creation_tasks.notifications.error.${data.action}.message`) }}
        </div>
      </div>
    </template>
  </pui-flex>
</template>

<script>
import services from '../../../../core/services';
import notificationItemMixin from '../../../../platform/components/notifications/notificationItemMixin';
import { getFilenameFromDownloadUrl } from '../../../common/helpers/downloadHelper';
import { CreationActions, PosterCreationStates } from '../../constants';

export default {
  name: 'PuiPosterCreationNotification',
  mixins: [notificationItemMixin],
  data() {
    return {
      PosterCreationStates,
      CreationActions,
    };
  },
  computed: {
    /**
     * @returns {object[]} download objects for the poster pdfs
     */
    downloadUrls() {
      if (this.data.action !== CreationActions.DOWNLOAD) {
        return [];
      }

      return this.data.pdfUrls.map((url) => {
        const filename = getFilenameFromDownloadUrl(url) || url;

        return {
          id: url,
          url,
          filename,
        };
      });
    },
  },
  methods: {
    // NOTIF ACTIONS
    /**
     * Removes this notification
     *
     * @returns {void}
     */
    dismissNotification() {
      services.getService('notifications').removeNotification(this.id);
    },
  },
};
</script>
