import Vue from 'vue';

/**
 * Formats a formats copies object.
 * @param formatsCopiesMap - Object with format Id properties containing copies object (label and copies number)
 * @returns the formatted string
 */
const formatFormatsCopies = (formatsCopiesMap: {
  [formatId: string]: { copies: number | string };
}): string => {
  const result = [];
  for (const formatId in formatsCopiesMap) {
    const formatCopies = formatsCopiesMap[formatId];
    if (formatCopies && Number.isInteger(Number(formatCopies.copies)) && formatCopies.copies > 0) {
      result.push(
        `${Number(formatCopies.copies)}(${(
          Vue.filter('piivoTranslate') as (arg: unknown) => string
        )(formatCopies)})`
      );
    }
  }

  return result.join(', ');
};

export const formatters = [
  {
    name: 'FormatsCopies',
    func: formatFormatsCopies,
  },
];
