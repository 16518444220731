<template>
  <pui-flex class="poster-type-gallery poster-type-gallery__root" direction="column">
    <pui-common-spinner
      v-if="loadingFamilies"
      class="poster-type-gallery__root-spinner"
      position="fixed"
    ></pui-common-spinner>

    <pui-error v-else-if="errorLoadingFamilies" position="fixed">
      <i18n path="poster.type_gallery.families.error.primary"></i18n>
      <i18n path="poster.type_gallery.families.error.secondary">
        <template #btnReload>
          <span class="pui-error__btn-reload" @click="getFamilies">{{
            $t('poster.type_gallery.families.error.btnReload')
          }}</span>
        </template>
      </i18n>
    </pui-error>

    <!-- No Families, or only 1 family but with no types -->
    <pui-error
      v-else-if="
        families.length === 0 ||
        (families.length === 1 && !loadingTypes && !currentFamilyTypes.length)
      "
      position="fixed"
      picto="mdi-alert"
    >
      <i18n path="poster.type_gallery.families.no_families.primary"></i18n>
      <i18n path="poster.type_gallery.families.no_families.secondary"> </i18n>
    </pui-error>

    <template v-else>
      <div v-if="$slots.title" class="poster-type-gallery__title">
        <slot name="title"> </slot>
      </div>

      <pui-flex v-if="families.length > 1">
        <!-- Multiple families -->
        <poster-family-carousel
          ref="familyCarousel"
          :items="families"
          :value="currentFamily ? [currentFamily] : []"
          class="poster-type-gallery__family-carousel"
          v-bind="familyCarouselProps"
          @change="onFamilyChanged"
        ></poster-family-carousel>
      </pui-flex>

      <!-- FAMILY TYPES GALLERY -->
      <pui-gallery
        v-if="currentFamily"
        ref="gallery"
        :key="currentFamily.itemId"
        :items="currentFamilyTypes"
        :getItemId="(type) => type.itemId"
        :getItemDimensions="() => ({ width: 0, height: 0 })"
        :loading="loadingTypes"
        :initialMode="GalleryMode.GRID"
        :hasMoreItems="false"
        :hasError="errorLoadingTypes"
        :gridProps="{ offsetHeight: META_HEIGHT_PX }"
        :showConfig="false"
        :groupedItems="true"
        :defaultGridCount="7"
        class="poster-type-gallery__gallery"
      >
        <template #groupSelf="{ item }">
          <i18n
            path="poster.type_gallery.types.group.title"
            class="poster-type-gallery__group-title"
            tag="div"
          >
            <template #name>
              <span class="poster-type-gallery__group-title-primary">
                {{ piivoTranslate(item) }}
              </span>
            </template>
            <template #children>
              <span class="poster-type-gallery__group-title-secondary">
                {{
                  $tc('poster.type_gallery.types.group.children', item.children.length, {
                    count: item.children.length,
                  })
                }}
              </span>
            </template>
          </i18n>
        </template>

        <template #default="{ item }">
          <pui-thumbnail
            :title="piivoTranslate(item)"
            v-bind="{
              src: item.thumbnails && item.thumbnails.length ? item.thumbnails[0] : null,
              ...typeThumbnailProps(item, currentFamily),
            }"
            v-on="typeThumbnailListeners(item, currentFamily)"
          >
            <template #center>
              <slot :item="item" :family="currentFamily" name="typeCenter"></slot>
            </template>
            <template #actions>
              <slot :item="item" :family="currentFamily" name="typeActions"></slot>
            </template>
            <template #title>
              <span>{{ piivoTranslate(item) }}</span>
            </template>
          </pui-thumbnail>
        </template>

        <template #error>
          <pui-error position="fixed">
            <i18n path="poster.type_gallery.types.error.primary"></i18n>
            <i18n path="poster.type_gallery.types.error.secondary">
              <template #btnReload>
                <span class="pui-error__btn-reload" @click="getFamilies">{{
                  $t('poster.type_gallery.types.error.btnReload')
                }}</span>
              </template>
            </i18n>
          </pui-error>
        </template>

        <template #noResults>
          <pui-flex direction="column" class="poster-type-gallery__types-no-results">
            <i18n path="poster.type_gallery.types.no_types"></i18n>
          </pui-flex>
        </template>

        <!-- Note that we don't use the noGroupResults slot, since we always add -->
        <!-- a virtual child on root types without children -->
      </pui-gallery>
    </template>
  </pui-flex>
</template>

<script>
import { GalleryMode } from 'piivo-ui/src/components/gallery/Gallery.vue';
import Vue from 'vue';

import puiThumbnail, { META_HEIGHT_PX } from '../../../common/components/thumbnail/Thumbnail.vue';
import { filterByScope } from '../../../common/helpers/scopeHelper';
import { getPosterService } from '../../services';
import posterFamilyCarousel from './FamilyCarousel.vue';

export default {
  name: 'PosterTypeGallery',
  components: {
    puiThumbnail,
    posterFamilyCarousel,
  },
  props: {
    /**
     * Props to apply to the family carousel component
     */
    familyCarouselProps: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Callback function to apply listeners on a single type thumbnail
     *
     * @type {(type: object, family: object) => object}
     */
    typeThumbnailListeners: {
      type: Function,
      default: () => ({}),
    },
    /**
     * Callback function to apply props on a single type thumbnail
     *
     * @type {(type: object, family: object) => object}
     */
    typeThumbnailProps: {
      type: Function,
      default: () => ({}),
    },
    /**
     * The default family to select on initial load
     * (if exists in the list of all families)
     */
    initialFamilyId: {
      type: String,
      default: null,
    },
    /**
     * Scope to filter the families and types
     */
    scopeFilter: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // Families
      loadingFamilies: true,
      errorLoadingFamilies: false,
      families: [],
      currentFamily: null,
      // Types
      currentFamilyTypes: [],
      loadingTypes: true,
      errorLoadingTypes: false,
      // Constants
      GalleryMode,
      META_HEIGHT_PX,
    };
  },
  methods: {
    // FILTERS
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
    // Families
    /**
     * Sets the current family and loads its types
     *
     * @param {object} family - the next family
     */
    setCurrentFamily(family) {
      this.currentFamily = family;
      this.$emit('changeFamily', this.currentFamily);
      this.getCurrentFamilyTypes(this.currentFamily);
    },
    /**
     * Retrieves all the families
     */
    async getFamilies() {
      this.loadingFamilies = true;
      this.errorLoadingFamilies = false;

      try {
        const families = await getPosterService('templates').loadFamilies();
        this.families = filterByScope(this.scopeFilter, families);

        // Try to match 'initialFamilyId'
        if (this.initialFamilyId) {
          const defaultFamily = this.families.find((f) => f.itemId === this.initialFamilyId);
          if (defaultFamily) {
            this.setCurrentFamily(defaultFamily);
          }
        }

        // If there is no default family (or not found), set the first family
        if (!this.currentFamily && this.families.length > 0) {
          this.setCurrentFamily(this.families[0]);
        }
      } catch (err) {
        this.errorLoadingFamilies = true;
      }

      this.loadingFamilies = false;
    },
    /**
     * Handles families selection change
     *
     * @param {object[]} families - array of selected families
     */
    onFamilyChanged(families) {
      if (families && families.length !== 1) {
        return;
      }

      this.setCurrentFamily(families[0]);
    },
    // Types
    /**
     * Clears the current types, retrieves the types of this.currentFamily
     */
    async getCurrentFamilyTypes() {
      if (!this.currentFamily) {
        return;
      }

      this.loadingTypes = true;
      this.errorLoadingTypes = false;
      this.currentFamilyTypes = [];

      try {
        const rootTypes = await getPosterService('templates').loadFamilyRootTypes(
          this.currentFamily.itemId
        );
        // Clone the array so we can mutate the elements without affecting the cache
        const types = filterByScope(this.scopeFilter, JSON.parse(JSON.stringify(rootTypes)));
        // In the gallery, a group without children will show "no children". But a root type without children
        // should still be able to be selected like any other leaf child.
        // So add the root type to its own children list
        types.forEach((rootType) => {
          if (!rootType.children || !rootType.children.length) {
            rootType.children = [{ ...rootType }]; // Clone the type so a child doesn't reference itself
          } else {
            rootType.children = filterByScope(this.scopeFilter, rootType.children);
          }
        });

        this.currentFamilyTypes = types;
      } catch (err) {
        this.errorLoadingTypes = true;
      }

      this.loadingTypes = false;
    },
  },
  /**
   * Component mounted hook
   */
  mounted() {
    // Do async
    this.getFamilies();
  },
};
</script>
