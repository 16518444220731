import Vue from 'vue';

import { ResolvedResponse, Response } from './../../../core/types/http';
import { PosterLog } from './logsTypes';
import { LogsDefinitions } from './types';

const LOGS_API_URL = '/api/signages/logs';

export default {
  /**
   * @param posters - the posters to create
   * @param parameters - creation parameters
   */
  async createPosters(
    posters: PosterLog.CreationLog[],
    parameters: PosterLog.CreationParameters | null
  ): Promise<PosterLog.PosterLog> {
    const response = (await Vue.http.post(LOGS_API_URL, {
      signages: posters,
      parameters: parameters || {},
    })) as ResolvedResponse<PosterLog.PosterLog>;

    return response.body;
  },

  /**
   * Get logs with paging parameters.
   *
   * @param index - Page index
   * @param pageSize - Page size
   */
  async getLogs(index: number, pageSize: number) {
    return Vue.http.get(`${LOGS_API_URL}/search?pageSize=${pageSize}&pageIndex=${index}`);
  },

  /**
   * Get log details with the log Id.
   *
   * @param logId - Log id
   * @param language - Language
   */
  async getLogDetails(logId: string, language: string) {
    return Vue.http.get(`${LOGS_API_URL}/${logId}/details?lang=${language}`);
  },

  async getSignagesLogs(
    signageIds: string[],
    forEdition: boolean
  ): Response<PosterLog.PosterLog[] | PosterLog.EditablePosterLog[]> {
    return Vue.http.get(LOGS_API_URL, {
      params: {
        ids: signageIds.join(','),
        forEdition,
      },
    }) as Response<PosterLog.PosterLog[] | PosterLog.EditablePosterLog[]>;
  },

  /**
   * Get log thumbnail  with the log Id.
   *
   * @param logId - Log id
   */
  async getLogThumbnail(logId: string) {
    return Vue.http.get(`${LOGS_API_URL}/${logId}/thumbnail`, { responseType: 'blob' });
  },

  /**
   * Delete a list of logs with their ids.
   *
   * @param toDelete - Array of logs id
   */
  async deleteLogs(toDelete: unknown[]) {
    return Vue.http.delete(`${LOGS_API_URL}/`, { body: toDelete });
  },

  /**
   * Archive a list of logs with their ids.
   *
   * @param {Array} toArchive - Array of logs id
   */
  async archiveLogs(toArchive: unknown) {
    return Vue.http.post(`${LOGS_API_URL}/archive`, toArchive);
  },

  /**
   * Get logs table definition.
   */
  async getLogsTableDefinition() {
    return Vue.http.get(`${LOGS_API_URL}/definitions/table`);
  },

  /**
   * Get logs filters definition.
   */
  async getLogsFiltersDefinition(): Response<LogsDefinitions.FiltersDefinition> {
    return Vue.http.get(
      `${LOGS_API_URL}/definitions/filters`
    ) as Response<LogsDefinitions.FiltersDefinition>;
  },

  /**
   * Get logs details definition.
   */
  async getLogsDetailsDefinition() {
    return Vue.http.get(`${LOGS_API_URL}/definitions/details`);
  },

  /**
   * Patch to update logs
   *
   * @param logs  array contains object with signages
   * @param parameters - update parameters
   * */
  async patchLogs(logs: unknown[], parameters: PosterLog.CreationParameters | null) {
    return Vue.http.patch(`${LOGS_API_URL}`, { signages: logs, parameters });
  },

  /**
   * Patch to add thumbnail in new logs created
   *
   * @param logs  array contains object with signages id and thumbnail value
   * */
  async patchLogsWithThumbnails(logs: unknown[]) {
    return Vue.http.patch(`${LOGS_API_URL}/thumbnails`, logs);
  },
};
