<template>
  <img :src="thumbnailURL" @error="setDefaultThumbnail" @load="$emit('loaded')" />
</template>
<script>
import { Scopes } from '../../common/constants/binaries';
import signagesApi from '../api/signages';
import DEFAULT_THUMBNAIL_URL from '../static/img/default-thumbnail.png';

export default {
  name: 'PosterGalleryThumbnail',
  props: {
    /**
     * The id of the poster to display the thumbnail for
     */
    posterId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      thumbnailURL: signagesApi.getBinaryUrl(this.posterId, Scopes.Thumbnails),
    };
  },
  watch: {
    /**
     * Update the thumbnail if ever the signage is changed
     * @param {Object} signageRequest - the signage item
     */
    posterId(next, prev) {
      if (next !== prev) {
        // Switch to the default URL: the <img/> should update (almost) instantly
        this.thumbnailURL = DEFAULT_THUMBNAIL_URL;
        this.$nextTick(() => {
          // Then switch to the actual URL. The <img/> will only update once loading finishes
          this.thumbnailURL = signagesApi.getBinaryUrl(this.posterId, Scopes.Thumbnails);
        });
      }
    },
  },
  methods: {
    /**
     * Sets the displayed image as the default thumbnail
     *
     * @param {event} - the error event
     */
    setDefaultThumbnail(event) {
      this.thumbnailURL = DEFAULT_THUMBNAIL_URL;
    },
  },
};
</script>
