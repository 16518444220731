import Vue from 'vue';

import { getExtensionPoint } from '../../../../core/extensionPoints';
import { USER_LOGOUT } from '../../../../core/store/modules/coreModule';
import { getPosterService } from '../../services';

export const SET_CURRENT_SIGNAGE_ITEM_ID = 'SET_CURRENT_SIGNAGE_ITEM_ID';
export const SET_SELECTED_SIGNAGES = 'SET_SELECTED_SIGNAGES';
export const SET_FILTER = 'SET_FILTER';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const SET_SIGNAGES_PAGINATION = 'SET_SIGNAGES_PAGINATION';
export const RESET_SIGNAGES_PAGINATION = 'RESET_SIGNAGES_PAGINATION';
export const SIGNAGES_ARE_SYNCHRONIZED = 'SIGNAGES_ARE_SYNCHRONIZED';
export const SET_CREATION_FAMILY_ID = 'SET_CREATION_FAMILY_ID';
export const SET_BATCH_CREATION_DEFAULT_SEARCH_MODE_ID =
  'SET_BATCH_CREATION_DEFAULT_SEARCH_MODE_ID';
export const SET_BATCH_CREATION_SEARCH_MODE_FORM_VALUES =
  'SET_BATCH_CREATION_SEARCH_MODE_FORM_VALUES';
export const SET_LAST_BUILT_FAMILY = 'SET_LAST_BUILT_FAMILY';
export const SET_LAST_BUILT_TYPE = 'SET_LAST_BUILT_TYPE';

const MAX_PAGE_SIZE = 50;

export const NAMESPACE = 'poster';

/**
 * Function to get new state object.
 * @returns New state object
 */
function getNewState() {
  return {
    currentSignageItemId: null,
    selectedSignages: [],
    currentFilters: {},
    signagesPagination: {
      pageIndex: 1,
      pageSize: MAX_PAGE_SIZE,
      nbElements: 0,
      totalNumberOfItems: -1, // Default value -1 (if total number of items is unknown)
      totalNumberOfPages: -1, // Default value -1 (if total number of pages is unknown)
    },

    isSynchronized: true,
    creation: {
      familyId: null,
      lastBuiltFamily: null,
      lastBuiltType: null,
    },
    batchCreation: {
      searchModeId: null,
      searchModesFormState: {},
    },
  };
}

/**
 * Store template module definition.
 */
export const PosterModule = {
  namespaced: true,
  state: getNewState(),

  getters: {
    selectedSignages: (state) => state.selectedSignages,
    currentSignageItemId: (state) => state.currentSignageItemId,
    currentFilters: (state) => state.currentFilters,
    signagesPagination: (state) => state.signagesPagination,
    selectedNode: (state) => state.selectedNode,
    // Creation
    creationFamily: (state) => state.creation.familyId,
    lastBuiltFamily: (state) => state.creation.lastBuiltFamily,
    lastBuiltType: (state) => state.creation.lastBuiltType,
    batchCreationDefaultSearchModeId: (state) => state.batchCreation.searchModeId,
    batchCreationSearchModesFormState: (state) => state.batchCreation.searchModesFormState,
    batchCreationSearchModeFormState: (state) => (itemId) =>
      state.batchCreation.searchModesFormState[itemId],
  },

  actions: {
    [USER_LOGOUT]: {
      root: true,
      handler(context) {
        getPosterService('templates').clearCachedData();

        // Clear the engine's caches
        getExtensionPoint('poster.engine').clearCachedData();

        // Set a new state object
        Object.assign(context.state, getNewState());
      },
    },
  },

  mutations: {
    [SET_CURRENT_SIGNAGE_ITEM_ID](state, signageItemId) {
      state.currentSignageItemId = signageItemId;
    },
    [SET_SELECTED_SIGNAGES](state, selectedSignages) {
      state.selectedSignages = selectedSignages;
    },
    // Create / Update with new filter object
    [SET_FILTER](state, { name, value, operator }) {
      const filterObject = {
        value: value,
        operator: operator,
      };

      Vue.set(state.currentFilters, name, filterObject);
    },
    // Set new currentFilters object in state
    [CLEAR_FILTERS](state) {
      state.currentFilters = getNewState().currentFilters;
    },
    [SET_SIGNAGES_PAGINATION](state, signagesPagination) {
      state.signagesPagination = signagesPagination;
    },
    [RESET_SIGNAGES_PAGINATION](state) {
      state.signagesPagination = {
        ...getNewState().signagesPagination,
        // Don't reset pageSize which might have been changed
        pageSize: state.signagesPagination.pageSize,
      };
    },
    [SIGNAGES_ARE_SYNCHRONIZED](state, isSynchronized) {
      state.isSynchronized = isSynchronized;
    },
    [SET_CREATION_FAMILY_ID](state, familyId) {
      state.creation.familyId = familyId;
    },
    /**
     * @param {*} state - moddule state
     * @param {object} payload - the id of the search mode
     */
    [SET_BATCH_CREATION_DEFAULT_SEARCH_MODE_ID](state, itemId) {
      state.batchCreation.searchModeId = itemId;
    },
    /**
     *
     * @param {*} state - moddule state
     * @param {object} payload - mutation payload
     * @param {object} payload.itemId - the id of the search mode
     * @param {object} payload.values - the form values
     */
    [SET_BATCH_CREATION_SEARCH_MODE_FORM_VALUES](state, { itemId, values }) {
      Vue.set(state.batchCreation.searchModesFormState, itemId, { values });
    },
    [SET_LAST_BUILT_FAMILY](state, family) {
      state.creation.lastBuiltFamily = family;
    },
    [SET_LAST_BUILT_TYPE](state, type) {
      state.creation.lastBuiltType = type;
    },
  },
};

export default PosterModule;
