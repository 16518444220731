<template>
  <pui-flex class="poster-search-mode-selector poster-search-mode-selector__root">
    <ul>
      <li
        v-for="searchMode in items"
        :key="searchMode.itemId"
        :class="{
          selected: value && value.itemId === searchMode.itemId,
        }"
        class="poster-search-mode-selector__mode-element"
        @click="$emit('change', searchMode)"
      >
        <i class="picto mdi mdi-magnify" />
        <span>{{ piivoTranslate(searchMode) }}</span>
      </li>
    </ul>
  </pui-flex>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'PosterSearchModeSelector',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    /**
     * Search mode items
     */
    items: {
      type: Array,
      required: true,
    },
    /**
     * The selected search mode
     */
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    // FILTERS
    piivoTranslate(value) {
      return Vue.filter('piivoTranslate')(value);
    },
  },
};
</script>
